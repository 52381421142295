;(function ($) {
    //on load
    $(window).on('load', function () {})

    //on ready
    $(document).ready(function () {
        //Scroll to anchors
        $('.scrolling').click(function () {
            let target = $(this).attr('href').match('#')
            target = target.input.slice(target['index'])
            $('html, body').animate({ scrollTop: $(target).offset().top }, 500)
            return false
        })

        //Accordion
        // $('.accordion-btn').click(function () {
        //     $(this).toggleClass('opened')
        //     $(this).next().stop().slideToggle()
        // })

        //Slect2 example
        // $('.select2').select2({})

        $('.toggle-nav').click(function () {
            $('.header .nav-box').toggleClass('opened')
            $('body').toggleClass('fixed')
        })

        $('.toggle-search').click(function () {
            $('.header .search-box').toggleClass('opened')
        })

        //Random hero bg
        const images = [
            './img/hero-bg/hero-1.png',
            './img/hero-bg/hero-2.png',
            './img/hero-bg/hero-3.png',
            './img/hero-bg/hero-4.png',
            './img/hero-bg/hero-5.png',
            './img/hero-bg/hero-6.png',
        ]
        const randomImage = images[Math.floor(Math.random() * images.length)]
        $('.hero-section .bg-img').attr('src', randomImage)

        new Swiper('.hero-section .swiper', {
            spaceBetween: 20,
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })

        new Swiper('.swiper-six-col', {
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.75,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 5,
                },
                1220: {
                    slidesPerView: 6,
                },
            },
        })

        new Swiper('.swiper-five-col', {
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.45,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 5,
                },
            },
        })

        new Swiper('.swiper-two-col', {
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.05,
                },
                768: {
                    slidesPerView: 2,
                },
            },
        })

        new Swiper('.swiper-four-col', {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        })
    }) //END on ready
})(jQuery)
